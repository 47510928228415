import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { Grid } from 'site-react/components/page';
import theme from 'site-react/theme';

const imageStyle = css`
  position: relative;
  width: 100%;
`;

const contentStyle = css`
  text-align: center;

  @media (min-width: ${theme.breakpoints.md}px) {
    text-align: left;
  }
`;

const ProductFeature = ({ children, image, flip }) => (
  <Grid>
    <Grid.Item
      colSpan="6"
      colStart={flip ? '7' : undefined}
      justify="center"
      rowSpan={flip ? '1' : undefined}
      rowStart={flip ? '1' : undefined}
    >
      <div className={imageStyle}>{image}</div>
    </Grid.Item>
    <Grid.Item
      align="center"
      colSpan="5"
      colStart={flip ? '1' : undefined}
      rowSpan={flip ? '1' : undefined}
      rowStart={flip ? '1' : undefined}
    >
      <div className={contentStyle}>{children}</div>
    </Grid.Item>
  </Grid>
);

ProductFeature.propTypes = {
  children: PropTypes.node.isRequired,
  flip: PropTypes.bool,
  image: PropTypes.node.isRequired,
};

export default ProductFeature;
