import { css } from '@emotion/css';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SearchApi from 'site-react/api/SearchApi';
import { TextInput } from 'site-react/components/form';
import theme from 'site-react/theme';

import Suggestions from './Suggestions';

const LocationAutocomplete = ({
  boxShadow = null,
  clearErrors = () => {},
  errorText = '',
  initialValue = '',
  inputAttributes = {},
  labelText = '',
  onChangeCallback = () => {},
  placeholder = '',
  setIsAutocompleteOpen = () => {},
  setValue,
  status = null,
}) => {
  const [autocompleteResults, setAutocompleteResults] = useState(null);
  const [displayValue, setDisplayValue] = useState(() => initialValue ?? '');
  const [searchApiError, setSearchApiError] = useState(null);

  useEffect(() => {
    setIsAutocompleteOpen(autocompleteResults?.length > 0);
  }, [autocompleteResults?.length, setIsAutocompleteOpen]);

  const handleAutocomplete = useCallback(
    async (value) => {
      setIsAutocompleteOpen(true);
      /*
       * don't attempt to get autocomplete results if the input is empty
       * or we will always end up in the catch block
       */
      if (!value.trim() || value.length < 2) {
        setAutocompleteResults([]);
        setIsAutocompleteOpen(false);
        return;
      }

      try {
        const api = new SearchApi();
        const response = await api.getAutocomplete({
          searchString: value,
        });
        const { areas } = response.body;

        setAutocompleteResults(areas.splice(0, 5));
      } catch (apiException) {
        setSearchApiError(
          'There was a problem fetching locations: ',
          apiException.error,
        );
        setAutocompleteResults([]);
        setIsAutocompleteOpen(false);
      }
    },
    [setIsAutocompleteOpen],
  );

  const debouncedAutocomplete = useMemo(
    () => debounce(handleAutocomplete, 50),
    [handleAutocomplete],
  );

  return (
    <>
      <TextInput
        autoComplete="off"
        boxShadow={boxShadow}
        errorText={searchApiError ?? errorText}
        isValidationManaged
        labelText={labelText}
        {...inputAttributes}
        onChange={(event) => {
          onChangeCallback();
          clearErrors();
          setDisplayValue(event.target.value);
          debouncedAutocomplete(event.target.value);
        }}
        placeholder={placeholder ? placeholder : 'Start typing a location'}
        required
        status={status}
        type="text"
        value={displayValue}
      />
      {autocompleteResults?.length ? (
        <div
          className={css`
            position: absolute;
            z-index: ${theme.zIndex.modal};
          `}
        >
          <Suggestions
            close={() => {
              setAutocompleteResults(null);
            }}
            inputValue={displayValue}
            results={autocompleteResults}
            updateLocation={(name, anchorSlug, areaSlug) => {
              setValue('anchorSlug', anchorSlug);
              setValue('areaSlug', areaSlug);
              setDisplayValue(name);
              setAutocompleteResults([]);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

LocationAutocomplete.propTypes = {
  boxShadow: PropTypes.string,
  clearErrors: PropTypes.func,
  errorText: PropTypes.string,
  initialValue: PropTypes.string,
  inputAttributes: PropTypes.object,
  labelText: PropTypes.string,
  onChangeCallback: PropTypes.func,
  placeholder: PropTypes.string,
  setIsAutocompleteOpen: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['error', null]),
};

export default LocationAutocomplete;
